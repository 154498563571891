.App {
	text-align: center;
}

body {
	padding-top: 64px;
}
.app-body.readonly header button.MuiIconButton-root {
	pointer-events: inherit;
	opacity: 1;
}
.app-body.readonly label.MuiFormControlLabel-root {
	pointer-events: none;
}
.app-body.readonly span.MuiButtonBase-root {
	color: rgba(0, 0, 0, 0.38);
}
.app-body.readonly .MuiToggleButtonGroup-root {
	pointer-events: none;
	opacity: 0.8;
}
.app-body.readonly .custom-date-picker-required label.Mui-error {
	font-weight: normal;
}
.app-body.readonly .MuiFormControl-root.MuiTextField-root {
	pointer-events: none;
	opacity: 0.8;
}

.app-body.readonly button.MuiButtonBase-root.MuiButton-contained {
	pointer-events: none;
	opacity: 0.5;
}

.app-body.readonly button.MuiIconButton-root {
	pointer-events: none;
	opacity: 0.5;
}

.app-body.readonly .editable-always.MuiIconButton-root {
	pointer-events: inherit;
	opacity: 1;
}

.app-body.readonly .editable-always button.MuiButtonBase-root.MuiButton-contained {
	pointer-events: inherit;
	opacity: 1;
}
