.attachment-modal-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 95vw;
	height: 95vh;
	background-color: #fff;
	box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
	padding: 16px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.attachment-modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
}

.attachment-modal-content {
	display: flex;
	flex-grow: 1;
	overflow: hidden;
}

.attachment-list {
	max-height: 100%;
	overflow-y: auto;
	border-right: 1px solid #e0e0e0;
	padding-right: 16px;
	flex-shrink: 0;
}

.attachment-list-item {
	border-radius: 4px;
	margin-bottom: 4px;
}

.attachment-list-item.selected {
	background-color: #e3f2fd;
}

.attachment-list-item-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.attachment-display-container {
	flex-grow: 1;
	position: relative;
	margin-left: 16px;
	display: flex;
	flex-direction: column;
}

.attachment-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.attachment-filename {
	width: 100%;
	text-align: center;
	padding: 8px;
	background-color: #f0f0f0;
	z-index: 1;
}

.attachment-image-container,
.attachment-pdf-container {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: calc(100% - 120px); /* Adjust based on filename and button height */
	overflow: hidden;
}

.attachment-image,
.attachment-pdf {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.attachment-pdf {
	width: 100%;
	height: 100%;
	border: none;
}

.attachment-default-text {
	margin-bottom: 16px;
}

.attachment-download-container {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 16px 0;
}

.attachment-loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.no-attachments-box {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
	padding: 32px;
}

@media (max-width: 960px) {
	.attachment-list {
		display: none;
	}

	.attachment-display-container {
		margin-left: 0;
	}
}
