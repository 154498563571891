.menu-bars {
	font-size: 2rem;
	color: #f5f5f5;
	background: none;
	cursor: pointer;
	line-height: 0px;
}

.sidebar-menu {
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	position: fixed;
	left: -100%;
	transition: 850ms;
	z-index: 100;
	border-left: 0.2857rem solid #135292;
}

.sidebar-menu.active {
	left: 0;
	transition: 350ms;
	width: 100%;
}

.sidebar-menu ul {
	padding-inline-start: 0px;
}

.sidebar-overlay {
	visibility: visible;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
}

.sidebar-text {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 0px 8px 0px;
	list-style: none;
	height: 60px;
}

.sidebar-text a {
	text-decoration: none;
	color: #333;
	font-size: 18px;
	width: 95%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 16px;
	border-radius: 4px;
	position: relative;
}

.sidebar-text a:hover {
	background-color: #1976d2;
	color: #f5f5f5;
}

.sidebar-menu-items {
	width: 100%;
	height: calc(100vh - 150px);
}

.sidebar-menu-container {
	height: 100%;
	overflow: auto;
}

.sidebar-toggle {
	background-color: #00446b;
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

span {
	margin-left: 16px;
}
a[disabled] {
	pointer-events: none;
	color: #808080;
}

.expand-button {
	margin-left: auto !important;
	color: inherit !important;
}

.nested-item {
	padding-left: 30px !important;
}
